<template>
    <v-row no-gutters>
        <v-col cols="12" xxl="4" xl="4" lg="4" md="4" sm="4">
            <v-autocomplete v-model="countryCode" color="primary" :items="prefixes" prefix="+" item-title="country_code"
                item-value="country_code" variant="outlined" @keypress="validation.default.input.isNumber"
                validate-on="input lazy" :class="{ 'mr-2': smAndUp }">
                <template v-slot:item="{ props, item }">
                    <v-list-item v-bind="props" :subtitle="item.raw?.country_code" :title="item.raw?.country_name"
                        min-width="350">
                        <template v-slot:prepend>
                            <v-avatar size="24">
                                <v-img :src="item.raw?.image_url" :lazy-src="item.raw?.image_url"
                                    :alt="item.raw?.country_name" />
                            </v-avatar>
                        </template>
                    </v-list-item>
                </template>
            </v-autocomplete>
        </v-col>
        <v-col cols="12" xxl="8" xl="8" lg="8" md="8" sm="8">
            <v-text-field v-model="phoneNumber" variant="outlined" color="primary" placeholder="Masukan nomor handphone"
                @keypress="validation.default.input.isNumber" :rules="rules" validate-on="input lazy"
                persistent-placeholder />
        </v-col>
    </v-row>
</template>

<script setup>
import api from "@/api/api";
import * as validation from "@/utils/validation";
import { isValidPhoneNumber } from 'libphonenumber-js'
import { computed, defineModel, onMounted, ref, watch } from "vue"
import { useDisplay } from "vuetify/lib/framework.mjs";
const modelValue = defineModel({
    required: true,
    type: Array,
    default: ["62", ""]
})

const { smAndUp } = useDisplay()

const countryCode = ref("62")
const phoneNumber = ref("")

watch(countryCode, () => {
    phoneNumber.value = ""
})

const model = computed(() => {
    return [countryCode.value, phoneNumber.value]
})

watch(model, (value) => {
    modelValue.value = value
})

const prefixes = ref([])
async function init() {
    const result = await api.get('info/country-code')
    prefixes.value = result.data.response
}

onMounted(() => {
    init()
})

const rules = [
    (v) => {
        return !!v || "Silahkan isi bagian ini"
    },
    (v) => {
        return isValidPhoneNumber(`+${countryCode.value}${v}`) || "Nomor handphone tidak valid"
    }
]

watch(modelValue, (value) => {
    if (value[1].startsWith("0")) {
        modelValue.value[1] = modelValue.value[1].slice(1)
    }

    if (value[1].startsWith("62")) {
        modelValue.value[1] = modelValue.value[1].slice(2)
    }

    if (value[1].startsWith("+62")) {
        modelValue.value[1] = modelValue.value[1].slice(3)
    }
})
</script>

<style lang="scss" scoped></style>